exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-coming-soon-js": () => import("./../../../src/pages/coming-soon.js" /* webpackChunkName: "component---src-pages-coming-soon-js" */),
  "component---src-templates-blog-author-js": () => import("./../../../src/templates/blog-author.js" /* webpackChunkName: "component---src-templates-blog-author-js" */),
  "component---src-templates-blog-post-archive-js": () => import("./../../../src/templates/blog-post-archive.js" /* webpackChunkName: "component---src-templates-blog-post-archive-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-template-category-archive-js": () => import("./../../../src/templates/template-category-archive.js" /* webpackChunkName: "component---src-templates-template-category-archive-js" */),
  "component---src-templates-template-page-default-js": () => import("./../../../src/templates/template-page-default.js" /* webpackChunkName: "component---src-templates-template-page-default-js" */),
  "component---src-templates-template-product-js": () => import("./../../../src/templates/template-product.js" /* webpackChunkName: "component---src-templates-template-product-js" */)
}

